// import React, {useState, useEffect} from 'react';
// import {Link, useLocation } from 'react-router-dom';
// import {FaBars} from 'react-icons/fa';
// import {IconContext} from 'react-icons/lib'
// import {animateScroll as scroll} from 'react-scroll'
// import {
//     Nav, 
//     NavbarContainer, 
//     NavLogo, 
//     MobileIcon, 
//     NavMenu, 
//     NavItem, 
//     NavLinks,
//     NavBtn,
//     NavBtnLink,
//     KnowMoreLink
// } from './NavbarElements'

// const Navbar = ({ toggle }) => {
//     const location = useLocation();

//     const isKnowMorePage = location.pathname === '/know-more';

//     const scrollToSection = (sectionId) => {
//         const element = document.getElementById(sectionId);
//         if (element) {
//             window.scrollTo({
//                 top: element.offsetTop - 80, // Considering any header height or offset
//                 behavior: 'smooth',
//             });
//         }
//     };
    
//     const [scrollNav, setScrollNav] = useState(false)

//     const changeNav = () => {
//         if(window.scrollY >= 80) {
//             setScrollNav(true)
//         } else {
//             setScrollNav(false)

//             }
//         };

//         useEffect(() => {
//             window.addEventListener('scroll',changeNav)
//         }, []);

//         const toggleHome = () =>{
//             scroll.scrollToTop();
//         };

        
    
//   return (
//     <>
//     <IconContext.Provider value={{color: '#fff'}}>
//       <Nav scrollNav= {scrollNav}>
//           <NavbarContainer>
//               <NavLogo to='/' onClick={toggleHome}>Wiry</NavLogo>
//               <MobileIcon onClick={toggle}>
//                   <FaBars />
//               </MobileIcon>
//               <NavMenu>
//                   <NavItem>
//                   {isKnowMorePage ? (
//                                    <KnowMoreLink to="/" onClick={() => scrollToSection('about')}
//                                    smooth={true} 
//                                    duration = {500} 
//                                    spy= {true} 
//                                    exact= 'true' 
//                                    offset={-80}>About</KnowMoreLink>
//                                 ) : (
//                       <NavLinks to="about" 
//                       smooth={true} 
//                       duration = {500} 
//                       spy= {true} 
//                       exact= 'true' 
//                       offset={-80}>About</NavLinks>
//                                 )}
//                   </NavItem>
//                   <NavItem>
//                       <NavLinks to="products"
//                       smooth={true} 
//                       duration = {500} 
//                       spy= {true} 
//                       exact= 'true' 
//                       offset={-80}>Products</NavLinks>
//                   </NavItem>
//                   <NavItem>
//                       <NavLinks to="services"
//                       smooth={true} 
//                       duration = {500} 
//                       spy= {true} 
//                       exact= 'true' 
//                       offset={-80}>Services</NavLinks>
//                   </NavItem>
//                   <NavItem>
//                       <NavLinks to="signup"
//                       smooth={true} 
//                       duration = {500} 
//                       spy= {true} 
//                       exact= 'true' 
//                       offset={-80}>Sign Up</NavLinks>
//                   </NavItem>
//                   <NavItem>
//                     <KnowMoreLink to="/know-more">Know More</KnowMoreLink>
//                     </NavItem>
//               </NavMenu>
//               <NavBtn>
//                   <NavBtnLink to="/signin">Get Quote</NavBtnLink>
                  
//               </NavBtn>
//           </NavbarContainer>
//         </Nav>
//         </IconContext.Provider>
//     </>
//   );
// };

// export default Navbar
////////////////////////////////////////////////////////////////////
import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { FaBars } from 'react-icons/fa';
import { IconContext } from 'react-icons/lib';
import { animateScroll as scroll } from 'react-scroll';
import {
    Nav,
    NavbarContainer,
    NavLogo,
    MobileIcon,
    NavMenu,
    NavItem,
    NavLinks,
    NavBtn,
    NavBtnLink,
    KnowMoreLink
} from './NavbarElements';

const Navbar = ({ toggle }) => {
    const location = useLocation();
    const isKnowMorePage = location.pathname === '/know-more';
    const isSEOPage = location.pathname === '/service/seo';
    const isSocialMediaPage = location.pathname === '/service/social-media';
    const isVirtualToursPage = location.pathname === '/service/virtual-tours';

    const scrollToSection = (sectionId) => {
        const element = document.getElementById(sectionId);
        if (element) {
            window.scrollTo({
                top: element.offsetTop - 80,
                behavior: 'smooth',
            });
        }
    };

    const [scrollNav, setScrollNav] = useState(false);

    const changeNav = () => {
        if (window.scrollY >= 80) {
            setScrollNav(true);
        } else {
            setScrollNav(false);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', changeNav);
        return () => {
            window.removeEventListener('scroll', changeNav);
        };
    }, []);

    const toggleHome = () => {
        scroll.scrollToTop();
    };

    const handleAboutClick = () => {
        // if (isKnowMorePage || isSEOPage || isSocialMediaPage || isVirtualToursPage) {
            location.push("/");
            setTimeout(() => {
                scrollToSection('about');
            }, 200); // Adjust the delay as needed based on your page transition speed
        // }
    };

    const handleProductsClick = () => {
        // if (isKnowMorePage || isSEOPage || isSocialMediaPage || isVirtualToursPage) {
            // scroll.scrollToTop();
            setTimeout(() => {
                scrollToSection('products');
            }, 200); // Adjust the delay as needed based on your page transition speed
        // }
    };

    // const handleServicesClick = () => {
    //     // if (isKnowMorePage || isSEOPage || isSocialMediaPage || isVirtualToursPage) {
    //     //     scroll.scrollToTop();
    //         setTimeout(() => {
    //             scrollToSection('services');
    //         }, 200); // Adjust the delay as needed based on your page transition speed
    //     // }
    // };

    const handleSignUpClick = () => {
        // if (isKnowMorePage || isSEOPage || isSocialMediaPage || isVirtualToursPage) {
        //     scroll.scrollToTop();
            setTimeout(() => {
                scrollToSection('join');
            }, 200); // Adjust the delay as needed based on your page transition speed
        // }
    };

    return (
        <>
            <IconContext.Provider value={{ color: '#fff' }}>
                <Nav scrollNav={scrollNav}>
                    <NavbarContainer>
                        <NavLogo to='/' onClick={toggleHome}>Ariz Inc</NavLogo>
                        <MobileIcon onClick={toggle}>
                            <FaBars />
                        </MobileIcon>
                        <NavMenu>
                            <NavItem>
                                {isKnowMorePage || isSEOPage || isSocialMediaPage || isVirtualToursPage ? (
                                    <KnowMoreLink to="/" onClick={handleAboutClick}>
                                        About
                                    </KnowMoreLink>
                                ) : (
                                    <NavLinks to="about" smooth={true} duration={500} spy={true} exact='true' offset={-80}>
                                        About
                                    </NavLinks>
                                )}
                            </NavItem>
                            <NavItem>
                            {isKnowMorePage || isSEOPage || isSocialMediaPage || isVirtualToursPage ? (
                                    <KnowMoreLink to="/" onClick={handleProductsClick}>
                                        Location
                                    </KnowMoreLink>
                                ) : (
                                <NavLinks to="products" smooth={true} duration={500} spy={true} exact='true' offset={-80}>
                                    Location
                                </NavLinks>
                                )}
                            </NavItem>
                            {/* <NavItem>
                            {isKnowMorePage || isSEOPage || isSocialMediaPage || isVirtualToursPage ? (
                                    <KnowMoreLink to="/" onClick={handleServicesClick}>
                                        Services
                                    </KnowMoreLink>
                                ) : (
                                <NavLinks to="services" smooth={true} duration={500} spy={true} exact='true' offset={-80}>
                                    Services
                                </NavLinks>
                                )}
                            </NavItem> */}
                          <NavItem>
                            {isKnowMorePage || isSEOPage || isSocialMediaPage || isVirtualToursPage ? (
                                    <KnowMoreLink to="/" onClick={handleSignUpClick}>
                                        Join Us
                                    </KnowMoreLink>
                                ) : (
                                <NavLinks to="join" smooth={true} duration={500} spy={true} exact='true' offset={-80}>
                                    Join Us
                                </NavLinks>
                                )}
                            </NavItem>
                            {/* // { <NavItem>
                            //     <KnowMoreLink to="/know-more">Know More</KnowMoreLink>
                            // </NavItem> } */}
                        </NavMenu>
                        <NavBtn>
                            <NavBtnLink to="/getquote">Contact us</NavBtnLink>
                        </NavBtn>
                    </NavbarContainer>
                </Nav>
            </IconContext.Provider>
        </>
    );
};

export default Navbar;

