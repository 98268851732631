// Assuming this is your KnowMorePage component in './pages/know-more.js'
import React, { useState }  from 'react';
import styled from 'styled-components';
import Navbar from '../components/Navbar';
import Sidebar from '../components/Sidebar';
// import KnowMore360Image from './KnowMore360Image';

const KnowMoreContainer = styled.div`
  background: #000; /* Set your background color */
  height: 190vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const KnowMoreContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const KnowMoreText = styled.p`
  color: #fff; /* Set your text color */
  font-size: 1.5rem; /* Set your desired font size */
  text-align: center;
  max-width: 1040px;
  padding-top: 0;
  padding-bottom: 0;
`;

const KnowMorePage = () => {
    const[isOpen, setIsOpen] = useState(false)

    const toggle = () => {
        setIsOpen(!isOpen);
    };

    return (
        <>
        <Sidebar isOpen={isOpen} toggle={toggle} />
        <Navbar toggle={toggle} />
    <KnowMoreContainer>
      <KnowMoreContent>
      {/* <KnowMore360Image /> */}
        <KnowMoreText>
        <text>At Wiry, we're not just another digital marketing consultancy; we're your trusted ally in navigating the dynamic landscape of the online world. Our mission is simple: to empower businesses like yours to thrive in the digital realm.<br/><br/>

Who We Are:

Wiry is a team of passionate digital marketers, strategists, and creatives who believe in the power of a strong online presence. We understand that the digital landscape can be daunting, but with the right guidance, it can also be your biggest asset. That's where we come in.<br/><br/>

What We Do:

Our expertise spans the entire spectrum of digital marketing, from crafting compelling content and designing user-friendly websites to optimizing your online visibility through strategic SEO and social media campaigns. Whether you're a small startup or an established enterprise, we tailor our services to meet your unique needs and goals.<br/><br/>

Why Choose Wiry:<br/>

Tailored Solutions: No two businesses are alike, and neither are our strategies. We take the time to understand your brand, your audience, and your objectives to create customized digital solutions that deliver real results.<br/><br/>

Transparency: We believe in open communication and transparency. You'll always know what we're doing, why we're doing it, and how it contributes to your overall digital success.<br/><br/>

Proven Results: Our track record speaks for itself. We've helped businesses of all sizes elevate their online presence, increase brand awareness, and drive measurable growth.<br/><br/>

Continuous Learning: In the fast-paced world of digital marketing, staying ahead is key. Our team is committed to ongoing learning and adapting to the latest trends and technologies to ensure your strategies are always cutting-edge.<br/><br/>

Join the Wiry Family:<br/>

Whether you're looking to launch a new product, revamp your website, or simply boost your online visibility, Wiry is here to guide you every step of the way. Let's embark on this digital journey together and turn your online goals into reality.<br/><br/>

Get in touch with us today, and let's make your digital presence truly Wiry!.</text>
        </KnowMoreText>
      </KnowMoreContent>
    </KnowMoreContainer>
    </>
  );
};

export default KnowMorePage;
