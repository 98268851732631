import React, { useEffect } from 'react';
import ReactGA from 'react-ga4';
import './App.css';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import Home from './pages';
// import KnowMorePage from './pages/KnowMorePage';
import Navbar from './components/Navbar';
import GetQuote from './components/GetQuote';
// import SeoPage from './components/Services/Seo';
// import SocialMediaPage from './components/Services/SocialMedia';


const TRACKING_ID = "G-DS46QKSH5S";

function App() {
  useEffect(() => {
    ReactGA.initialize(TRACKING_ID);
    // Send pageview with a custom path
    ReactGA.send({ hitType: "pageview", page: "/landingpage", title: "Landing Page" });
  }, []);
  return (
    <Router>
      <Navbar />
      <Routes>
        <Route path="/" element={<Home/>} />
        <Route path="/getquote" element={<GetQuote/>} />
        {/* <Route path="/know-more" element={<KnowMorePage />} /> */}
        {/* <Route path="/service/seo" element={<SeoPage />} /> */}
        {/* <Route path="/service/social-media" element={<SocialMediaPage />} /> */}
      </Routes>
    </Router>
  );
}

export default App;

