import svg_1 from '../../images/meeting.jpg';
import svg_2 from '../../images/newyork.png';
import svg_3 from '../../images/contactus.png';

export const homeObjOne = {
    id: 'about',
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    headline: 'Who we are?',
    description: <text>At Ariz Inc, we believe data is the catalyst for unlocking new opportunities and driving growth. As a boutique digital transformation and development firm, we specialize in innovative solutions that harness the power of data. <br/><br/>

Our experts in data analytics, machine learning, and cloud engineering provide insights that enable stakeholders to make informed decisions, optimize operations, and achieve their goals. With our data-first approach, we drive transformational change and help clients stay ahead in a rapidly evolving landscape.<br/>
    </text>,
    imgStart: false,
    img: svg_1,
    alt: 'Car',
    dark: true,
    primary: true,
    darkText: true, // Dark green text
};

export const homeObjTwo = {
    id: 'products',
    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    headline: 'Why New York Businesses Trust Us',
    description: <text>As a New York-based firm, we understand the unique challenges and opportunities that local businesses face. Our deep knowledge of the market, combined with our technical expertise, makes us the ideal partner for your IT needs.</text>,
    buttonLabel: 'Learn More',
    imgStart: true,
    img: svg_2,
    alt: 'Notes',
    dark: true, // Dark green button
    primary: true,
    darkText: false, // Cream or Beige text
};

export const homeObjThree = {
    id: 'join',
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: 'Join our Team',
    headline: 'Joining Our Team is Easy',
    description: <text> Contact us to know more about open position</text>,
    buttonLabel: 'Start Now',
    imgStart: false,
    img: svg_3,
    alt: 'Presentation',
    dark: true, // Dark green button
    primary: true,
    darkText: true, // Cream or Beige text
};
