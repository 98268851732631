import React, {useState} from 'react';
import Video from '../../videos/video.mp4';
// import {Button} from '../ButtonElements';
import {HeroContainer, HeroBg, VideoBg, HeroContent, HeroH1, HeroP, HeroBtnWrapper} from './HeroElements';

const HeroSection = () => {
    const [hover, setHover] = useState(false);

    const onHover = () => {
        setHover(!hover);
    };

    return (
        <HeroContainer>
            <HeroBg>
                <VideoBg autoPlay loop muted src={Video} type='video/mp4' />
            </HeroBg>
            <HeroContent>
                <HeroH1>Welcome to Ariz Inc <br/> Your Trusted IT Partner in New York</HeroH1>
                <HeroP>
                At the center of Big Apple, elevate your business with cutting-edge IT solutions.
                </HeroP>
                <HeroBtnWrapper>
                    {/* {<Button to="services" 
                        onMouseEnter={onHover} 
                        onMouseLeave={onHover}
                        primary="true" 
                        dark="true" 
                        smooth={true} 
                        duration={500} 
                        spy={true} 
                        exact="true" 
                        offset={-80}>
                        {hover ? <ArrowForward /> : <ArrowRight />}
                    </Button>} */}
                </HeroBtnWrapper>
            </HeroContent>
        </HeroContainer>
    );
}

export default HeroSection;
