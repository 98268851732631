import React, { useState } from 'react';
import Footer from '../components/Footer'
import HeroSection from '../components/HeroSection';
import InfoSection from '../components/InfoSection';
import { homeObjOne, homeObjTwo, homeObjThree } from '../components/InfoSection/Data';
import Navbar from '../components/Navbar';
// import Services from '../components/Services';
import Sidebar from '../components/Sidebar';
import KnowMorePage from './KnowMorePage';


const Home = () => {
    
    const[isOpen, setIsOpen] = useState(false)

    const targetSection = KnowMorePage ? 'home-about' : 'about';

    const toggle = () => {
        setIsOpen(!isOpen);
    };

    return (
        <>
        <Sidebar isOpen={isOpen} toggle={toggle} />
        <Navbar toggle={toggle} />
        <HeroSection/>
        <InfoSection {...homeObjOne} />
        <InfoSection {...homeObjTwo} />
        {/* <Services/> */}
        <InfoSection {...homeObjThree} buttonTarget={targetSection} />
        <Footer />
        </>
    );
};

export default Home;


